import { H2, useHasMounted, useIsMobile } from '@cointracker/ui';
import IframeResizer from 'iframe-resizer-react';
import { useEffect, useState } from 'react';
import { container, glow, title } from '../Landing.css';
import { loveSpan, testimonialContainer } from './SocialTestimonals.css';

const IS_CHROMATIC = import.meta.env.PUBLIC_IS_CHROMATIC === 'true';
export const SocialTestimonals = () => {
  const hasMounted = useHasMounted();
  const [testimonialsToShow, setTestimonialsToShow] = useState(20);
  const isMobile = useIsMobile();
  useEffect(() => {
    if (isMobile) {
      setTestimonialsToShow(5);
    } else {
      setTestimonialsToShow(20);
    }
  }, [isMobile]);

  // Instructions to integrate with testimonail.to: https://testimonial.to/integrations/reactjs
  return (
    <section className={container}>
      <H2 className={title}>
        <div className={glow} />
        Users <span className={loveSpan}>LOVE</span> CoinTracker
      </H2>
      <div className={testimonialContainer}>
        {hasMounted && !IS_CHROMATIC && (
          <IframeResizer
            src={`https://embed.testimonial.to/w/cointracker/?theme=dark&card=base&loadMore=on&initialCount=${testimonialsToShow}&randomize=on&tag=all`}
            style={{
              width: '1px',
              minWidth: '100%',
              border: 'none',
            }}
          />
        )}
      </div>
    </section>
  );
};
