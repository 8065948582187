import { debounce } from 'lodash-es';
import { useEffect, useState } from 'react';

export const useWindowWidth = (delay = 500) => {
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0,
  );

  useEffect(() => {
    const handleResize = () => {
      // there are two cases we are trying to handle here:
      //   1. window.innerWidth is bigger than expected because the dom content is overflowing the body on x axis
      //   2. document.clientWidth is bigger than window.innerWidth in cases where the scrollbar is also existant etc
      const viewportWidth =
        window.innerWidth && window.document?.documentElement?.clientWidth
          ? Math.min(
              window.document.documentElement.clientWidth,
              window.innerWidth,
            )
          : window.innerWidth;
      setWidth(viewportWidth);
    };
    const debouncedHandleResize = debounce(handleResize, delay, {
      leading: true,
    });
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [delay]);

  return width;
};

interface useIsMobileProps {
  maxWidth?: number;
}
export function useIsMobile({ maxWidth = 992 }: useIsMobileProps = {}) {
  const width = useWindowWidth(100);

  if (width < maxWidth) {
    return true;
  }
  return false;
}
